<template>
  <div class="animated fadeIn container">
    <h1>회원관리</h1>
    <b-input-group class="mt-4" style="max-width:300px">
      <b-form-input
        type="text"
        class="form-control"
        placeholder="회원명 또는 아이디"
        v-model="searchKeyword"
        @keydown.native="searchKeywordInputKeyDown"
      />
      <b-btn variant="primary" class="fl" @click.prevent="changeSearchKeyword">검색</b-btn>
    </b-input-group>
    <!--b-checkbox class="float-right" v-model="canDownload" @change="clickDownloadCheck">발송결과 다운로드</!--b-checkbox-->
    <pulse-loader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size"></pulse-loader>
    <table class="border-table hover-table mt-4" id="users-table" v-if="items.length > 0">
      <thead>
        <tr>
          <td width="80px">번호</td>
          <td>아이디</td>
          <td width=" 180px">이름</td>
          <td width="240px">추천코드</td>
          <td width="240px">가입일시</td>
          <td width="120px" class="text-center">보유금액</td>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="item in items" @click="pushUserDetail(item.id)">
          <td>
            {{ item.id }}
          </td>
          <td>
            {{ item.email }}
          </td>
          <td>
            {{ item.username }}
          </td>
          <td class="td-recommend-code">
            {{ item.recommendCode }}
          </td>
          <td>
            {{ item.createdAt | dateFormat }}
          </td>
          <td>
            <span class="float-right mr-2">{{ item.money | numberFormat }} 원</span>
          </td>
        </tr>
      </tbody>
    </table>
    <b-pagination
      align="center"
      :total-rows="totalCount"
      v-model="currentPage"
      :per-page="20"
      :limit="10"
      class="mt-4 "
      @change="changePage"
    ></b-pagination>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import { mapGetters } from 'vuex'
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'

export default {
  name: 'Users',
  components: {
    PulseLoader,
  },
  data() {
    return {
      isLoading: true,
      loaderStyle: {
        color: '#ccc',
        size: '8px',
      },
      items: [],
      totalCount: Number.MAX_SAFE_INTEGER,
      currentPage: null,
      searchKeyword: null,
      canDownload: false,
    }
  },
  created() {
    this.searchKeyword = this.$route.query.searchKeyword
    this.currentPage = parseInt(this.$route.query.page) || 1
    this.getUsers()
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
  },
  methods: {
    async clickDownloadCheck() {
      await UserService.updateUsers({
        canDownload: !this.canDownload,
      })
      alert('업데이트 성공')
    },
    async getUsers() {
      this.isLoading = true
      var params = {
        page: this.currentPage,
        status: 0,
      }

      if (this.searchKeyword) {
        params.searchKeyword = this.searchKeyword
      }

      const response = await UserService.getUsers(params)
      if (response.status == 200) {
        this.items = response.data.list
        this.totalCount = response.data.totalCount
      }

      this.isLoading = false
    },
    pushUserDetail(userId) {
      this.$router.push({
        path: `/admin/users/${userId.toString()}`,
      })
    },
    rowClicked(item) {
      const userLink = this.userLink(item.id)
      this.$router.push({
        path: userLink,
      })
    },
    changePage(page) {
      var query = {
        page: page,
      }

      if (this.searchKeyword && this.searchKeyword.length > 0) {
        query.searchKeyword = this.searchKeyword
      }

      this.$router.push({
        path: '/admin/users',
        query: query,
      })
    },
    changeSearchKeyword() {
      this.$router.push({
        path: '/admin/users',
        query: {
          searchKeyword: this.searchKeyword,
        },
      })
    },
    searchKeywordInputKeyDown(e) {
      if (e.which === 13) {
        this.changeSearchKeyword()
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    '$route.query.searchKeyword'() {
      this.searchKeyword = this.$route.query.searchKeyword
      this.getUsers()
    },
    '$route.query.page'() {
      this.currentPage = this.$route.query.page
      this.getUsers()
    },
  },
}
</script>
<style scoped>
.td-recommend-code {
  max-width: 120px;
}

#users-table td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
